/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=REM:wght@300;400;500;600;700;800;900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@300;400;500;600;700;800;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900;6..12,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap'); */



/* html {
} */

html * {
    scroll-behavior: smooth;
    /* scroll-behavior: auto; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


.grayimg {
    -webkit-filter: grayscale(1);
    filter: gray;
    filter: grayscale(1);
}

body {
    font: 400 15px/1.6 'REM', 'MontSerrat', serif, sans-serif;
    color: #2b2b2b;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: #337ab7;
}

a:hover {
    color: #1f5280;
}

/* ---------------------------------------------- /*
 * Typography
/* ---------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
    font: 700 46px/1.2 'REM', 'MontSerrat', serif, sans-serif;
    margin: 5px 0 5px;
    color: #364153;
    text-transform: uppercase;
    ;
}

h1 {
    font-size: 64px;
    font-weight: 700;
    margin: 5px 0 15px;
}

h2 {
    font-size: 30px;
    font-weight: 700;
}

h3 {
    font-size: 22px;
    text-transform: uppercase;
    margin: 5px 0 10px;
}

h4 {
    font-size: 16px;
    margin: 5px 0 10px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 13px;
}

p {
    margin: 0 0 15px;
}

/* ---------------------------------------------- /*
 * Transition elsements
/* ---------------------------------------------- */

a,
.btn,
.navbar,
.form-control,
.portfolio-item-description {
    -webkit-transition: all 0.125s ease-in-out 0s;
    -moz-transition: all 0.125s ease-in-out 0s;
    -ms-transition: all 0.125s ease-in-out 0s;
    -o-transition: all 0.125s ease-in-out 0s;
    transition: all 0.125s ease-in-out 0s;
}

.portfolio-item-preview {
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

/* ---------------------------------------------- /*
 * Reset box-shadow
/* ---------------------------------------------- */

.btn,
.panel,
.progress,
.form-control,
.modal-content,
.form-control:hover,
.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* ---------------------------------------------- /*
 * Reset border-radius
/* ---------------------------------------------- */

.btn,
.plan,
.alert,
.label,
.form-control,
.modal-content,
.icons-widget li a,
.input-group-addon,
.panel-group .panel {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}

/* ---------------------------------------------- /*
 * Inputs styles
/* ---------------------------------------------- */

.form-control {
    background: #ffffffa3;
    border: 2px solid #D8E1E4;
    font-size: 14px;
    padding: 18px;
    border-radius: 5px;
    -webkit-appearance: none;
}

.input-lg,
.form-horizontal .form-group-lg .form-control {
    height: 62px;
}

.input-sm,
.form-horizontal .form-group-sm .form-control {
    height: 40px;
    font-size: 12px;
}

/* ---------------------------------------------- /*
 * Input-group
/* ---------------------------------------------- */

.input-group .form-control {
    z-index: auto;
}

.input-group-addon {
    background: #D8E1E4;
    border: 2px solid #D8E1E4;
    padding: 6px 15px;
    font-weight: 700;
}

/* ---------------------------------------------- /*
 * Custom button style
/* ---------------------------------------------- */

.btn {
    font-weight: 700;
    font-size: 14px;
    padding: 17px 27px;
    border: 0;
    text-transform: uppercase;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
    outline: inherit !important;
}

/* Button icons */

.btn .icon-before {
    margin-right: 6px;
}

.btn .icon-after {
    margin-left: 6px;
}

/* Button size */

.btn-lg,
.btn-group-lg>.btn {
    padding: 22px 24px;
    font-size: 14px;
}

.btn-sm,
.btn-group-sm>.btn {
    padding: 11px 18px;
    font-weight: 400;
    font-size: 12px;
}

.btn-xs,
.btn-group-xs>.btn {
    padding: 7px 14px;
    font-size: 10px;
}

.btn-list .btn {
    margin-bottom: 5px;
}

/* Button types*/

.btn-default {
    background: #EEEEEE;
}

.btn-custom-1,
.btn-custom-2 {
    background: transparent;
    border: 2px solid #337ab7;
    padding: 10px 25px;
    color: #337ab7;
}

.btn-custom-2 {
    border: 2px solid #222;
    color: #222;
}

.btn-custom-1:hover,
.btn-custom-1:focus,
.btn-custom-1:active,
.btn-custom-1.active {
    background: #21598a;
    border: 2px solid #21598a;
    color: #FFF;
}

.btn-custom-2:hover,
.btn-custom-2:focus,
.btn-custom-2:active,
.btn-custom-2.active {
    background: #222;
    border: 2px solid #222;
    color: #FFF;
}

.btn-custom-1-inverse,
.btn-custom-2-inverse {
    background: #337ab7;
    border: 2px solid #337ab7;
    padding: 15px 25px;
    color: #FFF;
}

.btn-custom-2-inverse {
    background: #222;
    border: 2px solid #222;
}

.btn-custom-1-inverse:hover,
.btn-custom-1-inverse:focus,
.btn-custom-1-inverse:active,
.btn-custom-1-inverse.active {
    background: transparent;
    border: 2px solid #337ab7;
    color: #337ab7;
}

.btn-custom-2-inverse:hover,
.btn-custom-2-inverse:focus,
.btn-custom-2-inverse:active,
.btn-custom-2-inverse.active {
    background: transparent;
    border: 2px solid #222;
}

/* ---------------------------------------------- /*
 * Alerts style
/* ---------------------------------------------- */

.alert {
    border: 0;
}

/* ---------------------------------------------- /*
 * Skill-bars
/* ---------------------------------------------- */

.skill-bar {
    background: #C4C4C4;
    display: table;
    height: 4px;
    width: 100%;
    margin: 10px -2px 20px -2px;
}

.skill-bar .skill-rate-on {
    background: #337ab7;
}

.skill-rate-on,
.skill-rate-off {
    display: table-cell;
    border-left: 2px solid #FFF;
    border-right: 2px solid #FFF;
}

/* ---------------------------------------------- /*
 * Progress bars
/* ---------------------------------------------- */

.progress {
    background: #E5E5E5;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 22px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
}

.progress-bar {
    background: #337ab7;
    box-shadow: none;
    text-align: right;
    padding-right: 10px;
    line-height: 22px;
    -webkit-transition: all .7s ease-in-out;
    -moz-transition: all .7s ease-in-out;
    -ms-transition: all .7s ease-in-out;
    -o-transition: all .7s ease-in-out;
    transition: all .7s ease-in-out;
}

/* ---------------------------------------------- /*
 * Tabs
/* ---------------------------------------------- */

.nav-pills,
.nav-tabs {
    margin-bottom: 20px;
}

.nav-pills>li>a {
    border-radius: 3px;
}

.nav-tabs>li>a {
    border-radius: 3px 3px 0 0;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
    background: #7db33a;
}

.dropdown-menu {
    border: 0;
    border-top: 2px solid #7db33a;
    background: #d8dbeff5;
    box-shadow: #6d6d6d80 0px 2px 16px;
    color: white;
    text-shadow: none;
}

/* ---------------------------------------------- /*
 * Panels
/* ---------------------------------------------- */

.panel {
    border: 0;
}

.panel-body {
    background: #f5f5f5;
}

.panel-group .panel-heading+.panel-collapse>.panel-body {
    border: 0;
}

/* ---------------------------------------------- /*
 * Modal
/* ---------------------------------------------- */

.modal-content {
    border: 0;
}

/* ---------------------------------------------- /*
 * Labels
/* ---------------------------------------------- */

.label {
    padding: .2em .6em .2em;
    font-size: 70%;
}

.label-custom-1 {
    background: #337ab7;
}

.label-custom-2 {
    background: #222;
}

/* ---------------------------------------------- /*
 * Inline icons
/* ---------------------------------------------- */

.line-icon-before {
    margin-right: 6px;
}

.line-icon-after {
    margin-right: 6px;
}

/* ---------------------------------------------- /*
 * Icon-list and icon widget
/* ---------------------------------------------- */

.icons-widget {
    padding: 0;
    margin: 0;
}

.icons-widget li {
    display: inline-block;
    margin-right: 5px;
}

.icons-widget li a {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 1.25em;
    color: #FFF;
    transition: 200ms;
    text-decoration: none;
    /* box-shadow: #0f72c980 0px 2px 16px; */
    background: #0f72c9;
}

.icons-widget li a:hover {
    /* background: rgb(83, 120, 149); */
    filter: brightness(1.2);
    box-shadow: 0 0 9px #2a84d3;
}

.icons-widget-sm li a {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: .8em;
}

.icons-widget-sm li {
    margin-right: 3px;
}

.icons-widget li:last-child {
    margin-right: 0;
}

.iconList li {
    padding: 5px 0;
}

.iconList li:before {
    content: "\f054";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 10px;
    text-align: left;
    padding: 0;
    margin: 0;
    display: inline-block;
    margin-right: 10px;
    line-height: 1em;
}

/* ---------------------------------------------- /*
 * Owl slider
/* ---------------------------------------------- */

.owl-theme .owl-controls .owl-buttons div {
    /* background: linear-gradient(135deg, rgb(9, 32, 63), rgb(83, 120, 149));   */
    background: linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255));
    font-size: 24px;
    opacity: 1;
    width: 44px;
    height: 44px;
    margin: 0;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 200ms;
}

.owl-theme .owl-controls .owl-buttons div:hover {
    filter: brightness(1.2);
}

.owl-theme .owl-controls .owl-page span {
    background: #a3133b !important;
    transition: 200ms;
    margin: 5px 4px;
}

.owl-prev {
    position: absolute;
    top: 50%;
    margin-top: -22px !important;
    left: 0px;
}

@media (min-width: 992px) {
    .owl-prev {
        margin-left: -44px !important;
    }

    .owl-next {
        margin-right: -44px !important;
    }
}

.owl-next {
    position: absolute;
    top: 50%;
    margin-top: -22px !important;
    right: 0px;
}

/* ---------------------------------------------- /*
 * Scroll-up
/* ---------------------------------------------- */

.scroll-up {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 32px;
    bottom: 190px;
    z-index: 999;
    transition: .2s;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255));
    box-shadow: rgba(0, 77, 255, 0.5) 0px 2px 16px;
}

.whatsapp-float {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 30px;
    bottom: 126px;
    z-index: 999;
    transition: .2s;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    /* background: rgb(243, 243, 243);
    box-shadow: rgba(12, 70, 52, 0.5) 0px 2px 16px; */
}

.whatsapp-float:hover {
    filter: brightness(1.1);
    width: 58px;
    height: 58px;
    right: 28px;
    bottom: 124px;
}

.scroll-up:hover {
    filter: brightness(1.5);
    width: 52px;
    height: 52px;
    right: 30px;
    bottom: 188px;
}

.scroll-up a {
    font-size: 18px;
    color: #FFF;
    transition: 200ms;
}

.scroll-up a:hover {
    color: #ffffff;
}

/* ---------------------------------------------- /*
 * General
/* ---------------------------------------------- */

/* .wrapper {
    overflow: hidden;
} */

.section {
    position: relative;
    padding: 30px 0;
}

.color-overlay {
    background: rgba(0, 0, 0, 0.5);
    padding: 60px 0;
}

.section-header {
    text-align: center;
}

.section-header,
.section-header h1,
.section-header h2,
.section-header h3,
.section-header h4,
.section-header h5,
.section-header h6 {
    color: #FFF;
}

.callout-dark,
.callout-light {
    background: #EBEFF0;
    text-align: center;
    padding: 60px 0;
}

.callout-dark {
    background: #222;
}

.rotate {
    text-shadow: 0 0 0 !important;
}

.long-down {
    margin-bottom: 40px;
}

.ajax-response {
    margin-top: 15px;
}

.alt-section {
    background: rgb(241, 244, 245);
    z-index: 99;
}

.alt-section {
    background: rgb(247 249 252);
    z-index: 99;
}

.rotate-section:before {
    top: 0;
    left: -10%;
    content: '';
    z-index: -1;
    width: 120%;
    height: 100%;
    position: absolute;
    background-color: rgb(247 249 252);
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    -ms-transform: rotate(-2deg);
    -o-transform: rotate(-2deg);
    transform: rotate(-2deg);
    /* -webkit-border-top: 1px solid rgba(0, 0, 0, 0.1);
        -moz-border-top: 1px solid rgba(0, 0, 0, 0.1);
        -o-border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.1); */
}

/* .rotate-section:after {
    bottom: 0;
    left: -10%;
    content: '';
    z-index: -1;
    width: 120%;
    height: 100%;
    position: absolute;
    background-color: rgb(241, 244, 245);
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    -o-transform: rotate(2deg);
    transform: rotate(2deg);
    -webkit-transform-origin: 0% 100%;
    -moz-transform-origin: 0% 100%;
    -ms-transform-origin: 0% 100%;
    -o-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
} */

/* ---------------------------------------------- /*
 * Navigation
/* ---------------------------------------------- */

.navbar {
    background: none;
    border-radius: 0;
    border: 0;
    padding: 25px 0;
    font-weight: 400;
    color: #FFF !important;
}

.navbar-custom .nav li a:hover,
.navbar-custom .nav li a:focus,
.navbar-custom .nav li span:hover,
.navbar-custom .nav li span:focus,
.navbar-custom .nav li.active:not(.dropdown) {
    background: none;
    outline: 0;
    color: #9ada33;
}

.nav>li>span {
    position: relative;
    display: block;
    padding: 15px 15px;
    font-size: 14px;
}

.navbar-custom .navbar-brand {
    font-weight: 700;
    font-size: 26px;
    color: #FFF;
    filter: drop-shadow(2px 4px 6px #00000090);
}

.navbar-custom .navbar-brand .ams-header {
    position: relative;
}

.navbar-custom .navbar-brand .ams-header>p {
    font-size: 12px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 9px;
    bottom: -56px;
    transition: 200ms;
    
}

.navbar-custom .navbar-brand .ams-header>p>img {
    height: 30px;
    transition: 200ms;
    
}

.navbar-color .navbar-brand .ams-header>p>img,
.custom-collapse .navbar-brand .ams-header>p>img{
    bottom: -43px;
    height: 24px;
}

.navbar-color .navbar-brand .ams-header>p,
.custom-collapse .navbar-brand .ams-header>p {
    font-size: 9px !important;
    right: 9px !important;
    bottom: -43px;
}

.navbar-brand>div>img {
    height: 74.61px !important;
    margin-top: -6px !important;
    transition: cubic-bezier(0.68, -0.55, 0.27, 1.55) 300ms;
}

.custom-collapse .navbar-brand>div>img,
.navbar-color .navbar-brand>div>img {
    height: 60px !important;
    margin-top: 2px !important;
    /* margin-top: 3px !important; */
}

.navbar-custom .navbar-toggle .icon-bar {
    background: #FFF;
}

.navbar-custom .navbar-nav>li>a,
.navbar-custom .navbar-nav .dropdown-menu>li>a {
    color: #FFF;
    font-size: 14px;
}

.navbar-custom .navbar-nav .dropdown-menu {
    padding: 0;
}

.navbar-custom .navbar-nav .dropdown-menu:not(.products-highlight)>ul>li>span:not(.active):hover,
.navbar-custom .navbar-nav .dropdown-menu:not(.products-highlight)>ul>li>a:hover {
    /* background-color: #5981bb;
    color: #f0f7ff !important; */
    background-color: #4a86cd;
    /* color: #bbe4ff !important */
    color: #f0f7ff !important;
}

.navbar-custom .navbar-nav .dropdown-menu.products-highlight>ul>li:has(span:not(.active)):hover,
.navbar-custom .navbar-nav .dropdown-menu.products-highlight>ul>li:hover {
    filter: brightness(1.2)
}


/* .navbar-custom .navbar-nav .dropdown-menu.products-highlight>ul>li>a:hover>p {
    color: #f0f7ff !important;
} */

.navbar-color,
.custom-collapse {
    background: #2352ae;
    /* background: linear-gradient(135deg, #261675 0%, #00abff 140%); */
    /* background: linear-gradient(135deg, #233988  0%, rgb(0 168 255) 100%); */
    /* box-shadow: 0px 1px 10px 0px #17213170; */
    padding: 10px 0;
}

.navbar-color-automation,
.custom-collapse-automation {
    /*background: linear-gradient(135deg, rgb(44, 31, 66) 0%, rgb(157, 117, 235) 140%);*/
    background: linear-gradient(135deg, #261675 0%, #00abff 140%);
    box-shadow: 0px 1px 10px 0px #17213170;
    padding: 10px 0;
}

.navbar-color-integrations,
.custom-collapse-integrations {
    /*background: linear-gradient(135deg, rgb(0, 47, 74) 0%, rgb(39, 152, 219) 100%);*/
    background: linear-gradient(135deg, #261675 0%, #00abff 140%);
    box-shadow: 0px 1px 10px 0px #17213170;
    padding: 10px 0;
}

.navbar-color-otp,
.custom-collapse-otp {
    /*background: linear-gradient(135deg, rgb(11, 61, 66) 0%, rgb(102, 215, 215) 140%);*/
    background: linear-gradient(135deg, #261675 0%, #00abff 140%);
    box-shadow: 0px 1px 10px 0px #17213170;
    padding: 10px 0;
}

.navbar-color-shortUrl,
.custom-collapse-shortUrl {
    /*background: linear-gradient(135deg, rgb(52, 30, 16) 0%, rgb(146, 96, 63) 140%);*/
    background: linear-gradient(135deg, #261675 0%, #00abff 140%);
    box-shadow: 0px 1px 10px 0px #17213170;
    padding: 10px 0;
}

.navbar-color-qr,
.custom-collapse-qr {
    /*background: linear-gradient(135deg, rgb(31, 31, 31) 0%, rgb(128, 124, 99) 140%);*/
    background: linear-gradient(135deg, #261675 0%, #00abff 140%);
    box-shadow: 0px 1px 10px 0px #17213170;
    padding: 10px 0;
}

.navbar-color-whatsapp,
.custom-collapse-whatsapp {
    /*background: linear-gradient(135deg, rgb(44, 31, 66) 0%, rgb(157, 117, 235) 140%);*/
    background: linear-gradient(135deg, #261675 0%, #00abff 140%);
    box-shadow: 0px 1px 10px 0px #17213170;
    padding: 10px 0;
}

.navbar-color .nav li a:hover,
.navbar-color .nav li a:focus,
.navbar-color .nav li.active,
.navbar-color .navbar-nav>li.active a {
    color: #9de726;
}

.navbar-color .navbar-nav>li>a,
.navbar-color .navbar-nav .dropdown-menu>li>a,
.custom-collapse .navbar-nav>li>a,
.custom-collapse .navbar-nav .dropdown-menu>li>a {
    color: rgb(255, 255, 255);
}

.custom-collapse .navbar-nav {
    text-align: left;
}

.custom-collapse .navbar-nav>li.active {
    border: 0;
}

.navbar-color .navbar-toggle .icon-bar,
.custom-collapse .navbar-toggle .icon-bar {
    background: rgb(255, 255, 255);
}

ul.nav {
    width: max-content;
}

.dropdown-menu p {
    color: #000;
    font-size: 11px;
    font-weight: 100;
    margin: 0px;
    text-transform: none;
}

@media (min-width: 768px) {
    .dropdown-menu a:hover {
        color: #3d46b0 !important
    }

    .dropdown-menu a {
        font-size: 13px;
        color: #a3133b !important;
    }
}

@media (max-width: 992px) {
    .crowdy-portal {
        visibility: hidden !important;
    }
}

@media (max-width: 767px) {
    .dropdown-menu {
        border-left: 2px solid #9de726 !important;
        margin-left: 20px;
    }

    .dropdown-menu a {
        color: white;
    }

    .dropdown-menu p {
        color: #7ca6c9;
    }

    .nav>li>span {
        padding: 10px 15px;
    }
}

.navbar-right>li>a:not(.skip-text-transform),
.navbar-right>li>span {
    text-transform: uppercase;
    font-size: 12.5px !important;
    text-shadow: rgba(0, 0, 0, 0.74) 7px 1px 20px;
}

/* ---------------------------------------------- /*
 * Intro
/* ---------------------------------------------- */

#intro {
    background: linear-gradient(135deg, #261675 0%, #00abff 140%);
    /* background: linear-gradient(#233988  0%, rgb(0 168 255) 100%); */
    /* background: linear-gradient(#2230d3, rgb(192 2 90)); */
    z-index: auto !important;
    padding: 125px 0 110px 0;
    text-align: center;
    height: 500px
}

#intro,
#intro h1,
#intro h2,
#intro h3,
#intro h4,
#intro h5,
#intro h6 {
    color: #FFF;
}

#abstract,
#abstract h1,
#abstract h2,
#abstract h3,
#abstract h4,
#abstract h5,
#abstract h6 {
    color: #FFF;
}

.hello {
    margin: 0 0 100px;
}

/* ---------------------------------------------- /*
 * About
/* ---------------------------------------------- */

/* #about-header {
	background: url(../images/bg1.jpg) no-repeat center fixed;
	-webkit-background-size: cover;
			background-size: cover;
} */

/* ---------------------------------------------- /*
 * Service
/* ---------------------------------------------- */

.service {
    text-align: center;
    /*margin: 0 0 40px;*/
}

.service .icon {
    font-size: 32px;
    color: #337ab7;
}

.service h4 {
    text-transform: uppercase;
}

.paraxify {
    background-attachment: fixed !important;
}

/* ---------------------------------------------- /*
 * Portfolio
/* ---------------------------------------------- */

.portfolio-item {
    margin-bottom: 20px;
    overflow: hidden;
}

.portfolio-item-preview {
    position: relative;
}

.portfolio-item:hover .portfolio-item-preview {
    -webkit-transform: scale(1.1) rotate(2deg);
    -moz-transform: scale(1.3) rotate(3deg);
    -ms-transform: scale(1.3) rotate(3deg);
    -o-transform: scale(1.3) rotate(3deg);
    transform: scale(1.2) rotate(3deg);
}

.portfolio-item:hover .portfolio-item-description {
    opacity: 1;
    bottom: 0;
}

.portfolio-item-description {
    background: rgba(255, 255, 255, .5);
    position: absolute;
    right: 0;
    bottom: -70px;
    left: 0;
    padding: 25px;
    text-align: center;
    opacity: 0;
    color: #222;
}

/* ---------------------------------------------- /*
 * Testimonials
/* ---------------------------------------------- */

#clients h3,
#clients p {
    text-align: center;
}

#clients .owl-item {
    text-align: center;
}

#technologies .owl-item,
#products .owl-item {
    text-align: center;
}

#clients .avatar img {
    height: 84px;
    width: 84px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}

#clients blockquote {
    border: 0;
    padding: 10px 10px;
    margin: 0;
}

#clients blockquote p {
    margin: 15px 0 5px;
}

.stars {
    margin-bottom: 5px;
    color: #f1c40f;
}

.star-off {
    color: #999;
}

/* ---------------------------------------------- /*
 * Team
/* ---------------------------------------------- */

.member img {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
}

.member {
    text-align: center;
}

.member-data {
    padding: 15px 0 10px 0;
}

.member-data h3 {
    margin: 5px 0 0px;
}

.member-data span {
    font-size: 12px;
}

/* ---------------------------------------------- /*
 * Contact
/* ---------------------------------------------- */

#contact-data {
    background: #ebeff0;
    text-align: center;
}

#map-section {
    position: relative;
    height: 450px;
    width: 100%;
}

#map {
    height: 450px;
    width: 100%;
}

#footer {
    background: #2352ae;
    /* background: linear-gradient(135deg, #261675 0%, #00abff 140%); */
    padding: 20px 0;
    color: #FFF;
}

.footer-automation {
    background: linear-gradient(135deg, #261675 0%, #00abff 140%) !important;
}

.footer-integrations {
    background: linear-gradient(135deg, #261675 0%, #00abff 140%) !important;
}

.footer-otp {
    background: linear-gradient(135deg, #261675 0%, #00abff 140%) !important;
}

.footer-shortUrl {
    background: linear-gradient(135deg, #261675 0%, #00abff 140%) !important;
}

.footer-qr {
    background: linear-gradient(135deg, #261675 0%, #00abff 140%) !important;
}

.footer-whatsapp {
    background: linear-gradient(135deg, #261675 0%, #00abff 140%) !important;
}

#footer p {
    font-size: 12px;
    margin: 5px 0;
}

/* ---------------------------------------------- /*
 * Mouse animate icon
/* ---------------------------------------------- */

.mouse-icon {
    position: relative;
    border: 2px solid #fff;
    border-radius: 16px;
    height: 50px;
    width: 30px;
    margin: 0 auto;
    display: block;
    z-index: 10;
}

.mouse-icon .wheel {
    -webkit-animation-name: drop;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-play-state: running;
    animation-name: drop;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

.mouse-icon .wheel {
    position: relative;
    border-radius: 10px;
    background: #fff;
    width: 4px;
    height: 10px;
    top: 4px;
    margin-left: auto;
    margin-right: auto;
}

@-webkit-keyframes drop {
    0% {
        top: 5px;
        opacity: 0;
    }

    30% {
        top: 10px;
        opacity: 1;
    }

    100% {
        top: 25px;
        opacity: 0;
    }
}

@keyframes drop {
    0% {
        top: 5px;
        opacity: 0;
    }

    30% {
        top: 10px;
        opacity: 1;
    }

    100% {
        top: 25px;
        opacity: 0;
    }
}

/* ---------------------------------------------- /*
 * Preloader
/* ---------------------------------------------- */

#preloader {
    background: rgb(255, 255, 255);
    background-image: url("/assets/images/new background.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
}

#status,
.status-mes {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url("/assets/images/new background.gif"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 200px; */
    /* left: 50%; */
    /* margin: -225px 0 0 -100px; */
    position: absolute;
    /* top: 50%; */
    width: 100%;
    height: 100%;
}

.status-mes {
    background: none;
    left: 0;
    margin: 0;
    text-align: center;
    top: 65%;
}

.status-mes h4 {
    color: #222;
    margin-top: 30px;
}

/* ---------------------------------------------- /*
 * Media
/* ---------------------------------------------- */

@media (max-width: 992px) {

    .knob,
    .member {
        margin-bottom: 30px;
    }

    .iphone-mockup-container {
        overflow: hidden;
    }
}

@media (max-width: 767px) {
    .scroll-up {
        display: none !important;
    }

    .whatsapp-float {
        right: 12px;
        bottom: 104px;
    }

    .whatsapp-float:hover {
        right: 10px;
        bottom: 102px;
    }
}

@media only screen and (min-width: 768px) {
    .dropdown:hover .dropdown-menu {
        display: block;
    }
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'), url('/fonts/MontserratRegular/MonserratRegular-400.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url('/fonts/MontserratRegular/MonserratBold-700.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'SFLight';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/SF-Pro/sf-pro-text_light.woff2') format('woff2');
    /* unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; */
}

@font-face {
    font-family: 'SFRegular';
    font-style: bold;
    font-weight: 600;
    src: url('/fonts/SF-Pro/sf-pro-text_regular.woff2') format('woff2');
    /* unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; */
}

@media (max-width: 996px) {
    .col-md-6 {
        height: auto !important;
    }

    .illustration {
        height: 300px !important
    }

}

body {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
}

.blob {
    background: black;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    position: absolute;
    top: 0px;
    right: 0px;
    height: 20px;
    width: 20px;
    transform: scale(1);
    animation: pulse-black 1.5s infinite;
    /* border: 1pt #fff solid; */
}

.blob-animate {
    animation: pulse-black 1.5s infinite;
}

@keyframes pulse-black {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.blob.white {
    background: white;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    animation: pulse-white 1.5s infinite;
}

@keyframes pulse-white {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.blob.red {
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 1.5s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.blob.orange {
    background: rgba(255, 121, 63, 1);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
    animation: pulse-orange 1.5s infinite;
}

@keyframes pulse-orange {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
}

.blob.yellow {
    background: rgba(255, 177, 66, 1);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
    animation: pulse-yellow 1.5s infinite;
}

@keyframes pulse-yellow {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
}

.blob.blue {
    background: rgba(52, 172, 224, 1);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
    animation: pulse-blue 1.5s infinite;
}

.blob-animate.blue {
    /* box-shadow: 0 0 0 0 rgba(52, 172, 224, 1); */
    animation: pulse-blue2 1.5s infinite;
}

.blob-animate.blue-header {
    background: #ffffff3d;
    /* box-shadow: 0 0 0 0 rgba(52, 172, 224, 1); */
    animation: pulse-blue 1.5s infinite;
}

@keyframes pulse-blue {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
}

@keyframes pulse-blue2 {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(60, 137, 234, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(60, 137, 234, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(60, 137, 234, 0);
    }
}

.blob.green {
    background: rgba(51, 217, 178, 1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
    animation: pulse-green 1.5s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}

.blob.purple {
    background: rgba(142, 68, 173, 1);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 1);
    animation: pulse-purple 1.5s infinite;
}

@keyframes pulse-purple {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
    }
}

.icon-scroll,
.icon-scroll:before {
    position: absolute;
    left: 50%;
}

.icon-scroll {
    width: 30px;
    height: 50px;
    margin-left: -15px;
    top: 50%;
    margin-top: -35px;
    box-shadow: 0 0 0 2.5px #fff;
    border-radius: 25px;
}

.icon-scroll:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #fff;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
    animation-name: scroll;
}

@-webkit-keyframes scroll {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
    }
}

@keyframes scroll {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
    }
}

.navbar-custom .navbar-nav>li>span>a,
.navbar-custom .navbar-nav .dropdown-menu>li>span>a {
    color: #FFF;
    font-size: 14px;
    text-decoration: none;
}

/* Planes */

.plan {
    text-align: center;
    padding: 0 1px 30px 1px;
    border-radius: 5px;
    background: white;
    transition: 400ms;
    margin: 0px 5px 20px 5px;
    border: 1px solid #2d105475;

}

.plan>div:nth-child(1) {
    position: sticky;
    top: 70px;
    z-index: 1000;
    padding-top: 20px;
    background: #1b368c;
    color: white;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
}

.plan .sticky {
    position: sticky;
    top: 147.4px;
    background: white;
    z-index: 1000;
    padding-top: 20px;
}

.plan.business {
    box-shadow: rgb(63 15 88 / 9%) 0px 0px 15px 10px;
    border: 2px solid #8169a0;
    background: #551181;
    color: white;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
}

.plan.business:hover {
    box-shadow: rgb(63 15 88 / 20%) 0px 0px 15px 10px;
}

.plan.business>div:nth-child(1) {
    background: #551181;
    color: white;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
}

.plan h3 {
    color: #fff !important;
}

.plan.basic {
    box-shadow: rgb(15 22 88 / 9%) 0px 0px 15px 10px;
    border: 2px solid #4e75af;
    background: #1b368c;
    color: white;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
}

.plan.basic:hover {
    box-shadow: rgb(15 22 88 / 20%) 0px 0px 15px 10px;
}

.plan.enterprise {
    box-shadow: rgb(76 142 0 / 9%) 0px 0px 15px 10px;
    border: 2px solid #519245;
    background: #1a844a;
    color: white;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
}

.plan.enterprise:hover {
    box-shadow: rgb(76 142 0 / 20%) 0px 0px 15px 10px;
}

.plan.enterprise>div:nth-child(1) {
    background: #1a844a;
    color: white;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
}

.plan.custom {
    border: 2px solid rgb(58, 138, 162);
    box-shadow: rgb(58 138 162 / 9%) 0px 0px 15px 10px;
    background: #1d64a2;
    color: white;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
}

.plan.custom:hover {
    box-shadow: rgb(58 138 162 / 20%) 0px 0px 15px 10px;
}

.plan.custom>div:nth-child(1) {
    background: #1d64a2;
    color: white;
    background-position: top;
    background-size: cover;
    background-attachment: fixed;
}

.plan>div:first-child>h3 {
    text-transform: uppercase;
    padding-bottom: 15px;
}

.plan>h2 {
    margin-top: 15px;
    font-size: 14px;
    text-transform: none;
    color: #fff
}

.plan>div:nth-child(n+1)>h2 {
    text-transform: none;
    margin: 15px 0px;
    display: inline-flex;
    align-items: center;
}

.plan>div:nth-child(n+1)>h2 p {
    font-size: 14px;
    margin: 0px;
    min-height: 100px;
    color: white;
    display: flex;
    align-items: center;
}

.plan>div:nth-child(n+2) {
    margin: 0 19px;
}

.plan>div {
    border-bottom: 1px solid lavender;
}

.plan>div:last-child {
    margin-top: 25px;
    border-bottom: none !important;
    padding: 0px;
}

.plan .features {
    margin-top: 20px;
    margin: 20px 20px 0 20px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

@media (max-width: 992px) {
    .compare-container {
        display: none;
    }
}

@media (min-width: 992px) {
    .plan .features {
        min-height: 479px;
    }

    #social-links {
        position: absolute;
    }
}

@media (min-width: 1200px) {
    .plan .features {
        min-height: 288px;
    }
}

.plan .features div {
    display: flex;
    align-items: center;
    justify-content: left;
}

.plan .features p {
    margin: 0;
}

.plan .features img {
    height: 18px;
    margin-right: 5px;
}

.plan .feature {
    padding-bottom: 10px;
}

.plan button {
    font-size: 15px;
    background: #2059ae;
    color: white;
    border: none;
    padding: 5px 20px;
    border-radius: 2px;
    box-shadow: 0 0 0px 2px #2059ae90;
    transition: 200ms;
}

tr button {
    display: flex;
    align-items: center;
    font-size: 13px;
    background: #2059ae;
    color: white;
    border: none;
    padding: 2px 11px;
    border-radius: 2px;
    /* box-shadow: 0 0 0px 2px #2059ae90; */
    transition: 200ms;
    font-weight: 200;
    padding: 5px 12px 5px 10px
}

tr button:hover {
    filter: brightness(1.1);
}

.plan.business button,
tr .business button {
    background: #6a508d;
    box-shadow: 0 0 0px 2px #6a508d90;
}

.plan.enterprise button,
tr .enterprise button {
    background: #8bbb3f;
    box-shadow: 0 0 0px 2px #8bbb3f90;
}

.plan.custom button,
tr .custom button {
    background: #3c89ea;
    /* box-shadow: 0 0 0px 2px #3a8aa290; */
}

.plan button:hover {
    filter: brightness(1.2);
}

.compare-btn {
    width: auto !important;
    padding: 5px 20px;
    border-radius: 2px;
    background: #3c89ea;
    border: none;
    color: white;
    transition: 200ms;
    display: flex;
    align-items: center;
}

.compare-btn:hover {
    filter: brightness(1.1);
}

.compare-btn-container {
    justify-content: center;
    display: flex;
}

.compare-table {
    margin-top: 20px
}

.compare-table>table>thead th {
    position: sticky;
    top: 70px;
    background: white;
    padding: 10px;
    font-size: 22px;
}

.compare-table>table th:nth-child(n+2) {
    width: 165px !important;
    text-transform: uppercase;
    text-align: center;
}

.compare-table>table th:nth-child(2) {
    color: #4e75af;
}

.compare-table>table th:nth-child(3) {
    color: #6a508d;
}

.compare-table>table th:nth-child(4) {
    color: #8bbb3f;
}

.compare-table>table th:nth-child(5) {
    color: #3a8aa2;
}

.compare-table>table {
    width: 100%;
}

.compare-table>table>tbody img {
    height: 20px;
}

.compare-table>table>tbody tr {
    height: 36px;
}

.compare-table>table>tbody td:nth-child(n+2) {
    text-align: center;
}

.compare-table>table>tbody td:nth-child(1) {
    padding-left: 20px;
}

.compare-table>table>tbody tr:nth-child(2n) {
    background: #4b8bd10d;
}

thead,
tr {
    transition: 200ms;
}

.hide-button {
    display: none;
    height: 0;
    margin: 0;
    padding: 0;
}

.header-sticky th {
    box-shadow: 3px 3px 4px 0px #0000003d;
}

@media (min-width: 992px) {


    .wizard-card {
        perspective: 1200px;
        z-index: 3;
        margin-left: 0px;
        /* transition: 200ms; */
        transition: all 200ms ease 300ms;
    }

    .wizard-card>div {
        width: 500px;
        transform: rotateY(-25deg);
        transition: all 200ms ease 300ms;
        transform-style: preserve-3d;
    }

    .wizard-card img {
        transform-style: preserve-3d;
        /* filter: drop-shadow(0px 0px 1px #00000020) opacity(0.96); */
    }

    .wizard-card:hover,
    .wizard-card:has(>.active) {
        z-index: 4;
        /* position: absolute; */
    }

    .wizard-card>div:hover,
    .wizard-card>div.active {
        margin-top: -40px;
        width: 550px;
        /* position: absolute;
        left: calc (50% - 400px); */
        transform: rotateY(0deg) !important;
    }

    .wizard-card-one {
        z-index: 3;
    }

    .wizard-card-two {
        z-index: 2;
    }

    .wizard-card-three {
        z-index: 1;
    }
}

.phone-mockup-link,
.phone-mockup-link:link,
.phone-mockup-link:hover,
.phone-mockup-link:link:visited,
.phone-mockup-link:link:active {
    color: #1252e2;
    text-decoration: none;
}

span.emoji {
    cursor: text;
    direction: ltr;
    text-align: left;
    outline: none;
    margin: -4px 0 -3.3px !important;
    user-select: text;
    word-wrap: break-word;
    white-space: pre-wrap;
    -moz-user-modify: read-write;
    color: transparent;
    text-align: center;
    caret-color: #4373a4;
    font-size: 14px;
}

.emoji-mart-emoji {
    position: relative;
    display: inline-block;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    box-shadow: none;
}

.iphone-mockup {
    transition: 1.2s;
    opacity: 0.9;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.384));
}

.iphone-mockup:hover {
    z-index: 3 !important;
    opacity: 1;
}

/* MessageComposer */

.label {
    display: inline-block;
    padding: 0 0.545em;
    line-height: 1.818em;
    border-color: #818a91;
    background: #818a91;
    color: #fff;
    vertical-align: baseline;
    border-radius: 0.25em;
    font-weight: 500;
}

.label-success,
.panel-warning>.panel-heading .badge-success {
    border-color: #78bd5d;
    background: #78bd5d;
    color: #fff;
}

span.emoji {
    cursor: text;
    direction: ltr;
    text-align: left;
    outline: none;
    margin: -4px 0 -3.3px !important;
    user-select: text;
    word-wrap: break-word;
    white-space: pre-wrap;
    -moz-user-modify: read-write;
    color: transparent;
    text-align: center;
    caret-color: #4373a4;
    font-size: 14px;
}

/*span.emoji > span {
        -moz-user-focus: ignore;
        user-select: none;
    }*/

.content-editable {
    /*width: 100%;*/
    background: transparent;
    color: #4373a4;
    padding: 10px;
    resize: none;
    overflow: hidden;
    font-size: 14px;
    /*direction: ltr;*/
    text-align: left;
    white-space: pre-wrap;
    display: block;
    user-select: text;
    word-wrap: break-word;
    white-space: pre-wrap;
    outline: none;
    position: relative;
    /* min-height: calc(100% - 30px);
    max-height: calc(100% - 30px);*/
    /*  border-radius: 10px;
    background: #023c9230;
    margin: 15px;
    box-shadow: 0px 0px 0px 2px #0258921c;*/
    transition: 500ms;
}

.content-editable:focus {
    outline: none
}

.content-editable br {
    display: none;
}

.content-editable>.sms-text {
    word-wrap: break-word;
    white-space: pre-wrap;
    /*line-break: bre;*/
    outline: none;
    /*display: block;*/
}

/*table pagination button*/
.pagination>li.active>a,
.pagination>li.active>a:active,
.pagination>li.active>a:focus,
.pagination>li.active>a:hover,
.pagination>li.active>span {
    color: #fff;
    border-width: 0px;
    width: 29px !important;
    background: #3e76dc;
    box-shadow: 0px 0px 1px 2px #1459d870;
    height: 29px !important;
}


.message-container>.scroll>.ps-scrollbar-y-rail {
    /*width: 3px !important;
        background-color: #ffffff8f !important;*/
    user-select: none;
    -moz-user-focus: ignore
}

.message-container>.scroll>.ps-scrollbar-x-rail {
    /*width: 3px !important;
        background-color: #ffffff8f !important;*/
    user-select: none;
    -moz-user-focus: ignore
}


.sms-message-panel {
    background: linear-gradient(60deg, #f5f6fef5, #e3eaf9);
    display: flex;
    align-items: center;
    height: 515px;
    width: 100%;
    max-width: 100%;
    border-radius: 16px;
    flex-direction: column;
    position: relative;
    backdrop-filter: blur(4px);
    box-shadow: 0 0 2px 0px #18408f47;
    ;
}

.sms-message-panel.back-card-one {
    /* top: -10px;
        width: calc(100% - 20px);
        position: absolute; */
    bottom: -12px;
    width: calc(100% - 32px);
    position: absolute;
    border-radius: 0 0 12px 12px;
    height: 12px;
    background: linear-gradient(60deg, #f5f6fe7d, #e3eaf961);
}

.sms-message-panel.back-card-two {
    /* top: -20px;
        width: calc(100% - 40px);
        position: absolute; */
    bottom: -24px;
    width: calc(100% - 64px);
    position: absolute;
    height: 12px;
    border-radius: 0 0 12px 12px;
    background: linear-gradient(60deg, #f5f6fe47, #e3eaf93d);
}

.sms-message-panel .title {
    width: 100%;
    padding: 12px 12px 0 30px;
    font-size: 16px;
    height: 44px;
    color: #4373a4;
    display: flex;
    align-items: center;
}

.sms-message-panel>.tool-box {
    width: calc(100% - 20px)
}

.sms-message-panel>.tool-box .sms-count {
    font: bold 9px Arial !important;
    height: 13px !important;
    margin-top: 8px !important;
}

.message-container>.scroll {
    min-height: calc(100% - 30px);
    max-height: calc(100% - 30px);
    /*padding: 0 10px;*/
    border-radius: 8px;
    margin: 15px 20px;
    background: #c9d4eb;
    transition: 400ms;
}

/* .message-container > .scroll:hover, .message-container > .scroll-emoji:hover,
    .message-container > .scroll:focus-within, .message-container > .scroll-emoji:focus-within {
        box-shadow: 0px 0px 0px 2px #a4bcddb8;
    } */

.message-container>.scroll-emoji {
    min-height: calc(100% - 287.43px);
    max-height: calc(100% - 287.43px);
    /*min-height: 141.43px;
    max-height: 141.43px;*/
    position: relative;
    box-shadow: 0px 0px 0px 2px #a4bcdd0d;
    /*padding: 0 10px;*/
    border-radius: 8px;
    margin: 15px 20px;
    background: #c9d4eb;
}


.message-composer {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 8px 24px;
    min-width: 100%;
    max-width: 100%;
}



.message-composer .tool-box {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 10px;
    height: 65px;
    border-top: 1.5px solid #dee4f7;
}

.message-composer .tool-box>.left-options {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 50%;
}

.message-composer .tool-box>.right-options {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    width: 50%;
    padding-right: 5px
}

.message-composer .tool-box .tool-box-btn:hover {
    filter: none !important;
    margin-top: 0px;
}

.message-composer .tool-box .tool-box-btn {
    margin: 5px;
    cursor: pointer;
    height: 24px;
    transition: 200ms;
}

.message-container {
    min-height: calc(100% - 115px);
    width: 100%;
}

.content-editable[placeholder]:empty:before {
    content: attr(placeholder);
    color: #728baa;
}

.message-container>.emoji-mart {
    margin-left: 10px;
    width: calc(100% - 20px) !important;
}

svg> {
    transform: unset !important;
}

.blue-button {
    background: #0f72c9 !important;
    color: white !important;
    border: transparent !important;
    border-radius: 2px !important;
    padding: 0px 13px 0 3px !important;
    position: absolute !important;
    top: 7px !important;
    right: -114px !important;
    font-size: 12px !important;
    display: flex;
    width: auto;
}

.blue-button:hover {
    filter: brightness(1.2);
}

::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
    height: 8px;
    position: static;
    margin-right: -8px;
    margin-top: 12px;
}

::-webkit-scrollbar-thumb:hover {
    display: block;
}

::-webkit-scrollbar-thumb {
    background-color: #1b375b96;
    /* display: none; */
    border-radius: 5px;
    border: 2px;
    border-color: #00000000;
    box-shadow: inset #ffffff 0 0 0 1px !important;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
    background-color: transparent;
}

.col-md-3 {
    transition: all 200ms;
}

@media (min-width: 992px) {

    .sticky-section {
        position: sticky;
    }

    .services-sticky .card img {
        height: 39px !important;
    }

    .services-sticky .card>div:nth-child(3) {
        padding: 0 5px 5px !important;
    }

    .services-sticky {
        /* padding-bottom: 5px !important; */
        background: transparent !important;
    }

    .services-sticky::before {
        background-color: transparent !important;
    }

    /* .services-sticky .col-md-3:has(.card) {
    margin-bottom: 0px !important;
}  */

    .services-sticky .sticky-hide {
        display: none !important;
    }

    .services-sticky div:has(.sticky-hide) {
        height: auto !important;
        padding-top: 0 !important;
    }
}

@media (max-width: 992px) {
    .sticky-section {
        position: unset;
    }
}

.card {
    width: 100%;
    display: flex;
    justify-content: center;
    /* box-shadow: 0 0 3px 0px #00000021; */
    /* box-shadow: 0 0 0px 1px #abbdcb66; */
    border-radius: 6px;
    flex-direction: column;
    align-items: center;
    background: #4b8bd10d;
    /* transition: all 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28) 0s; */
    transition: 200ms;
    /* backdrop-filter: blur(2px); */
}

.card:hover:not(.card-nohover):not(.prod-hover),
.card.active {
    filter: brightness(1.12);
    background: #4b8bd133 !important;
    box-shadow: 0 0 7px 0px #4b8bd10d;
    /* backface-visibility: hidden; */
    /* -webkit-font-smoothing: subpixel-antialiased; */
    /* transform: scale(1.02) translateZ(0); */
}

div:has(>.card) {
    transition: 200ms;
}

div:has(>.card:not(.card-nohover):hover),
div:has(>.card.active) {
    padding: 0 5px !important;
    /* margin-bottom: 0px !important; */
}

.card:not(.card-nohover):hover {
    padding: 0 10px !important;
}

.card.prod-hover:hover {
    filter: brightness(1.15);
    box-shadow: 0 0 7px 0px #4b8bd10d;
    /* transform: scale(1.02) translateZ(0); */
}

.card.card-active {
    background: #4b8bd133 !important;
    transform: scale(1.02) translateZ(0);
}

.card>div:first-child {
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.card>div:nth-child(2) {
    color: #4994ec;
    font-weight: 700;
    font-size: 19px;
    padding: 5px;
    border-bottom: 1.5px solid #4994ec61;
    width: 90%;
    text-align: center;
}

.card p {
    margin: 0;
}

.card>div:nth-child(3) p {
    height: calc(100% - 30px);
    display: flex;
    align-items: center;

}

.card>div:nth-child(3) {
    font-size: 12.5px;
    padding: 10px 5px;
    width: 90%;
    text-align: center;
    display: flex;
    align-items: center;
    height: 120px;
    flex-direction: column;
    justify-content: center;
}

.card>div:nth-child(3)>a {
    width: auto !important;
    padding: 5px 12px 5px 10px;
    border-radius: 2px;
    background: #3c89ea;
    border: none;
    color: white;
    transition: 200ms;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.card>div:nth-child(3)>a:hover {
    filter: brightness(1.1);
}

.button {
    display: flex;
    align-items: center;
    font-size: 12px;
    background: #3c89ea;
    color: white;
    border: none;
    padding: 2px 11px;
    border-radius: 2px;
    /* box-shadow: 0 0 0px 2px #2059ae90; */
    transition: 200ms;
    font-weight: 200;
    padding: 5px 12px 5px 10px;
    cursor: pointer
}

.button:hover {
    filter: brightness(1.1)
}

.custom-input {
    /* width: 340px; */
    height: 40px;
    padding: 10px 15px;
    background-color: white;
    /* font-family: Helvetica, sans-serif; */
    font-weight: 300;
    font-size: 16px;
    border: 0px;
    border-radius: 4px;
    box-shadow: 0 0 0px 1px #6485c533;
    outline: none;
    /* text-align: center; */
}

.calculator-currency {
    display: flex;
    margin: 5px 0 0;
    /* transition: 200ms; */
}

.calculator-currency>div {
    font-weight: 700;
    padding: 4px 10px;
    background: #90a1cc30;
    /* border: 1px solid #6485c533; */
    cursor: pointer;
    transition: 200ms;
    color: #3c89ea;
    font-size: 11.5px;
    position: relative;
}

.calculator-currency>div:hover {
    background: #90a1cc6d
}


.calculator-currency>div.active {
    background: #3c89ea;
    color: white;
}

.calculator-currency>div:first-child {
    border-radius: 4px 0 0 4px;
}

.calculator-currency>div:last-child {
    border-radius: 0 4px 4px 0;
}

.hover-detail:hover>span {
    transform: scale(1) translateY(-10px);
}

.hover-detail>span {
    left: calc(50% - 25px);
    top: -52px;
    z-index: 101;
    position: absolute;
    font-size: 0.75rem;
    transform: scale(0);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.01071em;
    transform-origin: bottom center;
}

.hover-detail>span>span {
    width: 50px;
    height: 50px;
    background: #3c89ea;
    display: flex;
    transform: rotate(-45deg);
    align-items: center;
    border-radius: 50% 50% 50% 0;
    justify-content: center;
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.01071em;
}

.hover-detail>span>span>span {
    font-size: 10px;
    color: #fff;
    transform: rotate(45deg);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.01071em;
}

.header-price-sticky .calculator-pricind-detail {
    z-index: 1000;
    border-radius: 0 0 4px 4px;
    margin: 0;
    width: 115%;
    box-shadow: 0 0 0 1px #3c89ea;
    background: #e9eef6 !important;
}

/* .calculator-pricind-detail .calculator-currency>div>p{
    transition: all 200ms;
} */

.header-price-sticky .calculator-pricind-detail .calculator-currency>div>p {
    display: none;
}

.header-price-sticky .calculator-pricind-detail .calculator-currency>div>img {
    margin-right: 0 !important;
}

.header-price-sticky .calculator-pricind-detail .calculator-currency {
    margin-left: 0 !important;
    margin-bottom: 10px !important;
    margin: 0 5px 0 0;
}

.header-group-sticky>h5 {
    z-index: 100;
    border-radius: 0 0 4px 4px;
    /* width: calc(100% + 40px)!important; */
    /* box-shadow: 0 0 0 1px #3c89ea; */
    background: #e1e9f4 !important
}

.header-price-sticky .calculator-pricind-detail>p {
    font-size: 25px;
}

.calculator-pricind-detail {
    background: #e9eef6 !important;
    padding: 10px 0;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 200ms;
    ;
}

.calculator-pricind-detail>h4 {
    margin-bottom: 0px;
}

.calculator-pricind-detail>p {
    font-size: 25px;
    font-weight: 700;
    transition: all 200ms;
    ;
}

.calculator-pricing-note {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: rgba(75, 139, 209, 0.05);
    border-radius: 4px;
    padding: 15px 15px 8px 30px;
    /* border-left: #1a844a solid 3px; */
    margin-bottom: 20px;
}

.calculator-pricing-note::before {
    content: '';
    height: 100%;
    position: absolute;
    width: 4px;
    background: #4994ec;
    left: 0;
    top: 0;
    border-radius: 1px;
}

.calculator-pricing-note>h5,
.calculator-pricing-grid h5 {
    text-align: left;
    color: #4994ec;
}

.calculator-pricing-note>h5>img,
.calculator-pricing-grid h5>img {
    height: 36px;
    margin-right: 15px;
    margin-top: -8px;
}

.calculator-pricing-note>h6 {
    font-weight: 100;
    /* padding-left: 20px; */
    text-transform: initial;
    line-height: 1.5;
}

@media (min-width: 992px) {

    .calculator-pricing-grid {
        margin-top: 30px;
        display: grid;
        grid-auto-columns: auto;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 5px;
    }

    .calculator-pricing-grid>div {
        height: 100%;
    }

    .calculator-pricing-grid>div>div:last-child {
        margin-bottom: 0 !important;
    }

    .calculator-pricing-grid>div>div>div:last-child {
        margin-bottom: 0 !important;
    }
}

@media (max-width: 992px) {
    .calculator-pricing-grid {
        display: block;
    }
}

/* React-Suggestions */

.react-autosuggest__container {
    position: relative;
    background: white;
    box-shadow: 0 0 0px 1px #6485c533;
    border-radius: 4px;
}

.react-autosuggest__input {
    width: 340px;
    height: 40px;
    padding: 10px 5px;
    background-color: transparent;
    /* font-family: Helvetica, sans-serif; */
    font-weight: 300;
    font-size: 16px;
    border: 0px;
    border-radius: 4px;
    /* text-align: center; */
}

.react-autosuggest__input:focus {
    outline: none;
}

/* .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  } */

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 41px;
    width: 443.13px;
    max-height: 200px;
    overflow-y: scroll;
    /* border: 1px solid #aaa; */
    box-shadow: 0 0 0px 1px #6485c533;
    background-color: #fff;
    /* font-family: Helvetica, sans-serif; */
    font-weight: 300;
    font-size: 16px;
    border-radius: 4px;
    /* border-bottom-right-radius: 4px; */
    z-index: 1000;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
    border-top: 0px solid #ddd;
}

.react-autosuggest__suggestion--focused,
.react-autosuggest__suggestion--highlighted {
    background-color: #3c89ea;
    color: #fff;
}

.suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
}


.name {
    margin-left: 68px;
    line-height: 45px;
}

.highlight {
    color: #ee0000;
    font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
    color: #120000;
}

.MuiSlider-valueLabel>span {
    width: 36px;
    height: 36px;
}

/* React-Suggestions */

[id]:has(>section):before {
    content: '';
    display: block;
    height: 70px;
    margin-top: -70px;
    visibility: hidden;
}

/* input[type=number] {
    for absolutely positioning spinners
    position: relative; 
    padding: 5px;
    padding-right: 25px;
  } */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

/* input[type=number]::-webkit-outer-spin-button, 
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: inner-spin-button !important;
    width: 25px;
    position: absolute;
    top: 0;
    right: 0;
    height: 50%;
  } */

.lang-label {
    margin: 0;
    padding: 3px 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: #241e4954;
    /* text-transform: none; */
}

h1 {
    font-size: 22px;
}

h2 {
    font-size: 16px;
}

.section.rotate-section:not(.alt-section),
.section.rotate-section:not(.alt-section)::before {
    background: white;
}

.slider-styles .awssld__content {
    background: transparent;
}

.slider-styles .awssld__bullets {
    bottom: 10px;
    z-index: 100;
}

.slider-styles .awssld__bullets>button {
    cursor: pointer;
    background: #ffffffba;
    width: 8px;
    height: 8px;
    transform: unset;
}

.slider-styles .awssld__bullets>button:not(.awssld__bullets--active):hover {
    background: #ffffff;
}

.slider-styles .awssld__bullets>button.awssld__bullets--active {
    background-color: #38a9e2;
    width: 10px;
    height: 10px;
    box-shadow: 0 0 0 3px #38a9e273;
}

.slider-styles .awssld__controls span {
    height: 28px;
}

.slider-styles .awssld__controls span::before,
.slider-styles .awssld__controls span::after {
    border-radius: 4px;
    background-color: white;
}

.slider-styles .awssld__timer {
    bottom: 0;
    top: unset;
    background-color: #38a9e273;
    height: 4px;
    /* width: 50%; */
    /* margin-left: 25%; */
}